import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContactMeta } from "../data/seo-meta"

import "../styles/form.scss"

import rightArrow from "../images/common/right_arrow.svg"

const offices = [
  {
    name: "Headquarters",
    lines: ["33 Irving Place, Suite 7116", "New York, NY 10003", "USA"],
  },
  {
    name: "Development",
    lines: [
      "14 Chamberlain Avenue",
      "Ottawa, Ontario K1S 3T3",
      "Canada"
    ],
  },
  {
    name: "EU Regional Office",
    lines: ["5 New Street Square", "London, EC4A3TW", "United Kingdom"],
  },
]

const ContactPage = () => {
  const formId = "8c91e00f-f993-45a1-a094-3015c656765d"
  const formName = "slice_contact_2022"

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    script.async = true
    document.body.appendChild(script)

    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }))

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "1668259",
          formId,
          target: "#" + formName,
          cssRequired: "",
          cssClass: "",
          onFormSubmit: () => {
            const { onFormSubmit } = { formId, formName }
            if (onFormSubmit) {
              onFormSubmit()
            }
          },
        })
      }
    })
  })

  const submitForm = () => {
    document.getElementById(formName).getElementsByTagName("form")[0].submit()
  }

  return (
    <Layout>
      <SEO
        description={ContactMeta.description}
        path={ContactMeta.path}
        title={ContactMeta.title}
      />
      <div className="bg-[#f8f8f8]">
        <div className="container py-20 md:py-24">
          <div className="flex flex-wrap">
            <h1 className="h1 basis-full md:basis-1/2">Get in touch.</h1>
            <div className="basis-full md:basis-1/2">
              <div id="slice_contact_2022" />
              <button
                className="cta border-b-[3px] border-b-[#D91451] pb-2"
                onClick={submitForm}
              >
                Submit
                <img
                  className="inline-block w-8 -translate-y-1 ml-2"
                  src={rightArrow}
                  alt="right arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-20 md:py-24">
        <div className="flex flex-wrap">
          <div className="basis-full mb-8 md:basis-1/2 md:pr-8">
            <h2 className="h3 mb-4">Customer Support</h2>
            <table className="p1">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "70%" }} />
              </colgroup>
              <tr>
                <td>Email:</td>
                <td>
                  <a href="mailto:helpme@sliceinsurance.com" className="font-semibold">helpme@sliceinsurance.com</a>
                </td>
              </tr>
              <tr>
                <td>Call:</td>
                <td>
                  <a href="tel:+18556384465">1&#8209;855&#8209;638&#8209;4465</a>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <a href="tel:+18559591844">1&#8209;855&#8209;959&#8209;1844</a>
                </td>
              </tr>
            </table>
          </div>
          <div className="basis-full mb-8 md:basis-1/2 md:pl-8">
            <h2 className="h3 mb-4">General Inquiries</h2>
            <table className="p1">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "70%" }} />
                </colgroup>
                <tr>
                  <td>Email:</td>
                  <td>
                    <a href="mailto:info@sliceinsurance.com" className="font-semibold">info@sliceinsurance.com</a>
                  </td>
                </tr>
                <tr>
                  <td>Call:</td>
                  <td>
                    <a href="tel:+18666675423">1&#8209;866&#8209;667&#8209;5423</a>
                  </td>
                </tr>
              </table>
          </div>
        </div>

        <h2 className="h3 py-12">Global Slice Offices</h2>
        <div className="grid grid-cols-1 gap-16 md:grid-cols-3">
          {offices.map((office) => (
            <div
              className="px-10 py-4 rounded-2xl md:pb-24 bg-[#f8f8f8] drop-shadow"
              key={office.name}
            >
              <h3 className="text-[1.8rem] leading-[5.4rem] font-semibold">
                {office.name}
              </h3>
              {office.lines.map((line) => (
                <p className="p2" key={line}>
                  {line}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
